<template>
  <div>
    <div v-for="(item,index) in formdata.player_list" :key="index">
      <van-cell-group>
        <van-field v-model="item.name" label="选手名称" placeholder="请输入选手名字" />
      </van-cell-group>
      <div class="showpic_text">展示图：</div>
      <div class="picpadding">
        <van-uploader v-model="item.showpic" :after-read="afterRead" />
      </div>
      <div class="picpadding text-right">
        <van-icon class="fontsize24" name="delete-o" @click="deletpeople(index)" />
      </div>
    </div>
    <div class="picpadding text-right">
      <van-icon class="fontsize24" name="plus" @click="addpeople" />
    </div>
    <div class="upbuttom">
      <van-button type="info" block @click="submit">确认提交</van-button>
    </div>
  </div>
</template>

<script>
import { getQiniuToken } from '@/api/upload'
import { mapGetters, mapState } from 'vuex'
import { votesignpeople } from '@/api/votemange'

export default {
  data() {
    return {
      windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
      formdata: {
        player_list: [{
          name: '',
          showpic: []
        }]
      },
      openid: ''
    }
  },
  computed: {
    ...mapGetters(['baseSrc']),
    ...mapState('upload', ['upload_token', 'tokenTime'])
  },
  mounted() {
    this.id = window.location.search.split('=')[1]
    this.openid = localStorage.getItem('openid')
  },
  methods: {
    deletpeople(index) {
      this.formdata.player_list.splice(index, 1)
    },
    addpeople() {
      this.formdata.player_list.push({
        name: '',
        showpic: []
      })
    },
    async submit() {
      this.formdata.player_list.forEach(item => {
        if (item.name === '') {
          this.$toast.fail('信息填写不完整')

          return false
        }
        const pic = []
        item.showpic.forEach(val => {
          pic.push(val.picimg)
        })
        item.show_pic = pic.join(',')
      })
      const newup = JSON.parse(JSON.stringify(this.formdata.player_list))
      newup.forEach(item => {
        delete item.showpic
      })
      const res = await votesignpeople(this.id, newup, this.openid)
      if (res.code === 0) {
        this.$toast.success('报名成功')
        this.$router.go(-1)
      }
    },
    async afterRead(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      const curTime = new Date().getTime()
      // 无upload_token或者超时则重新获取token  后端设置超时时间为 一小时
      if (!this.upload_token || !(curTime - this.tokenTime < 3500000)) {
        const { data } = await getQiniuToken()

        this.$store.commit('upload/SET_UPLOAD_TOKEN', data)
        this.$store.commit('upload/SET_UPLOAD_TOKEN_TIME', curTime)
      }

      const res = await this.$store.dispatch('upload/updateFile', file)
      // console.log(res, 'store返回的数据')
      if (res.code === 0) {
        file.status = 'success'
        file.picimg = res.data
      }
    }

  }
}
</script>

<style scoped>
.showpic_text{
  color: #646566;
  font-size: 14px;
  padding: 10px 16px;
}
.picpadding{
  padding: 10px 16px;
}
.fontsize24{
  font-size: 24px;
}
.text-right{
  text-align: right;
}
.margin-bottom{
  margin-bottom:15px
}
.text-center{
  text-align: center;
}
.upbuttom{
  margin: 50px;
}
</style>
